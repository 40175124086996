/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Skeleton } from "@material-ui/lab";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Chip, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { shopSlice } from "./_redux/shopSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox } from "@material-ui/core";

import { icons, roles, metodiPagamento, scontiMetodiPagamento } from "../../config/config";
import * as apiClienti from "../../api/clienti";

const { actions } = shopSlice;

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};


const validationSchemaBuilder = (richiediExtra, richiediDatiVendita) => {
  return yup.object({

    idCliente: richiediDatiVendita ? yup
      .number("Inserire un cliente valido")
      .moreThan(0, "Selezionare un cliente")
      .required("Seleziona un cliente") : undefined,
    metodoPagamento: richiediDatiVendita ? yup
      .number("Inserire un metodo di pagamento valido")
      .moreThan(0, "Selezionare un metodo di pagamento")
      .required("Seleziona un metodo di pagamento") : undefined, 
    note: yup
      .string("Inserire una descrizione valida")
      .min(3, "Minimo tre caratteri"),
      //.max(500, "Massimo cinquecento caratteri"),
  });

};

const formFields = {
  idCliente: {
    label: "Cliente",
    placeholder: "Selezionare un cliente...",
    newLabel: 'Aggiungi nuovo', 
    newLink: '/clienti/#/new'
  },
  metodoPagamento: {
    label: "Metodo di pagamento",
    placeholder: "Selezionare un metodo...",
  },
  note: {
    label: "Note",
    placeholder: "Note..",
    rows: 3
  },
  extra: {
    label: "Richiesta EXTRA (mese corrente)",
    description: "Prosegui senza spuntare la casella per una Richiesta REGOLARE (mese successivo)"
  },
};

const note = formFields.note;

const useStyles = makeStyles({
  roypet: {
    borderColor: "#313d85",
    color: "#313d85",
  },
  roydermal: {
    borderColor: "#c9207c",
    color: "#85241d",
  },
});

export const DropdownItemToggler = React.forwardRef((props, ref) => {
  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  //handle button color
  const color = role === roles.agenteRoypet ? "#021c5e" : "#da291c";
  return (
    <Button
      style={{ backgroundColor: color, borderColor: color }}
      className="font-weight-bold"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </Button>
  );
});

DropdownItemToggler.displayName = "DropdownItemToggler";

const divisionNames = {
  0: "Tutti",
  1: "RoyPet",
  2: "RoyDermal",
}

export const ShopGrid = ({
  elements,
  cartName,
  displayName,
  richiediExtra,
  richiediDatiVendita,
  api,
  redirectTo,
  maxDescriptionLength = 50,
  imageHeight = 100,
}) => {
  // const initialCount = Object.fromEntries( elements.map( e => [e.id, 0]) );
  // const [counts, setCounts] = useState(initialCount);
  const elementsMap = new Map(elements.map((e) => [e.id, e]));
  const [richiestaLoading, setRichiestaLoading] = useState(false);
  const [richiestaExtra, setRichiestaExtra] = useState(false);
  const [richiestaIdCliente, setRichiestaIdCliente] = useState(0);
  const [richiestaMetodoPagamento, setRichiestaMetodoPagamento] = useState(0);
  const [richiestaNote, setRichiestaNote] =useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [divisionFilter, setDivisionFilter] = useState(0);
  const [result, setResult] = useState("");
  const classes = useStyles();
  

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  //handle button color
  const [color] = useState(role === roles.agenteRoypet ? "#021c5e" : "#da291c");

  // Shop redux
  const dispatch = useDispatch();
  const { cart, sconto } = useSelector(
    (state) => ({
      cart: state.shop[cartName + "Cart"],
      sconto: state.shop[cartName + "CartSconto"],
    }),
    shallowEqual
  );
  const getCartValue = (id) => {
    const value = cart[id];
    if (typeof value === "undefined") return 0;
    return value;
  };
  const setCartValue = (id, value) => {
    if (value < 0) value = 0;
    dispatch(actions.setCartQuantity({ id, newQuantity: value, cartName }));
  };
  const resetCart = () => dispatch(actions.resetCart({ cartName }));
  
  const getScontoValue = (id) => {
    const value = sconto[id];
    if (typeof value === "undefined") return 0;
    return value;
  };
  const getScontoFinalValue = (id) => {
    const value = sconto[id];
    if (typeof value === "undefined") return 0;
    if (typeof value === "string") {
      const num = parseFloat(value);
      if (!isNaN(num)) return num;
      else return 0;
    } 
    return value;
  };
  const setScontoValue = (id, value) => {
    if (value < 0) value = 0;
    dispatch(actions.setCartSconto({ id, newSconto: value, cartName }));
  };

 
  // Invio richiesta
  useEffect(() => {
    if (richiestaLoading) {
      let richieste = {};
      if (richiediExtra) {
        richieste.extra = richiestaExtra + 0; // api needs bool as int
      }
      if (richiediDatiVendita) {
        richieste.sconto = sconto;
        richieste.idCliente = richiestaIdCliente;
        richieste.metodoPagamento = richiestaMetodoPagamento;
        richieste.note = richiestaNote;
      }
      api
        .add(user.authToken, { cart, cartName, ...richieste })
        .then((data) => {
          setResult(203);
          setRichiestaLoading(false);
          resetCart();
          setRichiestaExtra(false);
          setRichiestaIdCliente(0);
          setRichiestaMetodoPagamento(0);
          setRichiestaNote('');
        })
        .catch((e) => {
          setResult(e.code);
          setRichiestaLoading(false);
        });
    }
    /*eslint-disable-next-line*/
  }, [richiestaLoading]);

  const [clienti, setClienti] = useState([]);
  useEffect(() => {
    if (richiediDatiVendita) {
      apiClienti
        .getAll(user.authToken)
        .then((data) =>
          setClienti(
            data.map((cliente) => ({ value: cliente.id, label: cliente.fullname }))
          )
        );
    }
    /*eslint-disable-next-line*/
  }, []);

  const formik = useFormik({
    initialValues: {
      idCliente: richiestaIdCliente,
      metodoPagamento: richiestaMetodoPagamento,
      note: "",
      extra: 0,
    },
    enableReinitialize: true,
    validationSchema: () => validationSchemaBuilder(richiediExtra, richiediDatiVendita),
    onSubmit: (values) => {
      setRichiestaIdCliente(parseInt(values.idCliente)); 
      setRichiestaMetodoPagamento(parseInt(values.metodoPagamento)); 
      setRichiestaNote(values.note);
      setRichiestaLoading(true)
    },
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  return (
    <>
      <div className="float-right">
        <InputGroup size="lg" className="my-5">
          <InputGroup.Prepend>
            <InputGroup.Text id="search-form">Cerca</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control 
            key={`form_search_${Math.round((new Date()).getTime() / 1000)}`}
            aria-label="Small" aria-describedby="search-form" 
            type="text"
            style={{maxWidth: 300}}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            
          />
          <DropdownButton
            key={`button_dropdown`}
            as={InputGroup.Append}
            variant="secondary"
            title={divisionNames[divisionFilter]}
          >
            {Object.entries(divisionNames).map((entry) => <>
              <Dropdown.Item key={entry[0]} onClick={() => setDivisionFilter(entry[0])}>{entry[1]}</Dropdown.Item>
            </>)}
          </DropdownButton>
        </InputGroup>
      </div>
      <div className="clearfix"></div>
      <Row xs={1} md={2} xl={3} xxl={4}>
        {elements.filter((element) => {
          if (searchFilter != '' 
            && element.name.toLowerCase().indexOf(searchFilter.toLowerCase()) == -1
            && element.description.toLowerCase().indexOf(searchFilter.toLowerCase()) == -1) return false;
          if (divisionFilter > 0 && element.division != divisionFilter) return false;
          return true;
        }).map((element, idx) => {
          const elClass =
            parseInt(element.division) === 2
              ? classes.roydermal
              : classes.roypet;
          return (
            <Col key={idx} className="px-4 py-2 d-flex">
              <Card className="gutter-b card-custom card-stretched flex-fill">
                <Card.Header
                  className={`border-0 py-4 ${
                    element.available ? "" : "text-muted"
                  }`}
                  style={{ minHeight: "45px" }}
                >
                  <h4 style={{ margin: "auto 0" }}>
                    {element.name}{" "}
                    {element.available ? "" : " - Non disponibile"}
                  </h4>
                </Card.Header>
                {element.imageUrl !== "" && element.imageUrl !== "0" ? ( // TODO: REMOVE THE 0 ?
                  <div
                    className="card-img-top mt-0"
                    key={element.imageUrl}
                    style={{
                      height: `${imageHeight}px`,
                      background: `url("${
                        element.imageUrl.startsWith("/")
                          ? process.env.REACT_APP_PUBLIC_URL + element.imageUrl
                          : element.imageUrl
                      }") center no-repeat`,
                      backgroundSize: "contain",
                    }}
                  ></div>
                ) : null}
                <Card.Body className={`py-4 ${
                          element.available ? "" : "text-muted"
                        }`}>
                  {element.description.length > 0 ? (
                    element.description.length > maxDescriptionLength ? (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {element.description}
                          </Tooltip>
                        )}
                      >
                        <Card.Text>
                          {element.description.substring(
                            0,
                            maxDescriptionLength
                          ) + "..."}
                        </Card.Text>
                      </OverlayTrigger>
                    ) : (
                      <Card.Text>{element.description}</Card.Text>
                    )
                  ) : null}
                  {element.quantity ? (
                    <ListGroup className="list-group-flush">
                      <ListGroupItem
                        className={`px-0 ${
                          element.available ? "" : "text-muted"
                        }`}
                      >
                        {displayName[0].toUpperCase() + displayName.substring(1)} per collo: {element.quantity}
                      </ListGroupItem>
                      {typeof element.cost !== "undefined" ? (
                        <ListGroupItem
                          className={`px-0 ${
                            element.available ? "" : "text-muted"
                          }`}
                        >
                          Valore per collo: {getScontoFinalValue(element.id)
                          ? (<>
                            <span style={{textDecoration: "line-through"}}>{element.cost}€</span>
                            <span className="ml-2">{((1 - getScontoFinalValue(element.id)/100) * element.cost).toFixed(2)}</span>
                          </>)
                          : element.cost}€
                        </ListGroupItem>
                      ) : null}
                    </ListGroup>
                  ) : 
                    <ListGroup className="list-group-flush">
                      {typeof element.cost !== "undefined" ? (
                        <ListGroupItem
                          className={`px-0 ${
                            element.available ? "" : "text-muted"
                          }`}
                        >
                          Valore: {getScontoFinalValue(element.id)
                          ? (<>
                            <span style={{textDecoration: "line-through"}}>{element.cost}€</span>
                            <span className="ml-2">{((1 - getScontoFinalValue(element.id)/100) * element.cost).toFixed(2)}</span>
                          </>)
                          : element.cost}€
                        </ListGroupItem>
                      ) : null}
                    </ListGroup>
                  }
                  {richiediDatiVendita ? <ListGroup className="list-group-flush">
                      {typeof element.cost !== "undefined" ? (
                        <ListGroupItem
                          className={`px-0 ${
                            element.available ? "" : "text-muted"
                          }`}
                        >
                          <Form.Group>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="scontoGroupPrepend">Sconto</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                type="text" 
                                placeholder="Percentuale di sconto"
                                aria-describedby="scontoGroupPrepend"
                                value={getScontoValue(element.id)}
                                key={element.id}
                                onChange={(e) => {}}
                                onInput={(e) => {
                                  // checks if a string is a positive number or empty
                                  let val = e.target.value.replace(',', '.').replace(/[^0-9.]/g, '');
                                  const shouldWait = val.endsWith('.') || val.endsWith('.0');
                                  if (shouldWait && val.search(/\.(.*)(\.)/) >= 0) {
                                    val = val.substr(0, val.length - 1);
                                  }
                                  const parsed = shouldWait ? NaN : parseFloat(val);
                                  if (!isNaN(parsed) && parsed >= 0) {
                                    if (parsed <= 100) {
                                      setScontoValue(
                                        element.id, 
                                        Math.round(parsed * 100) / 100
                                      );
                                    } 
                                  } else {
                                    setScontoValue(element.id, val);
                                  }
                                }}
                                autoFocus
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>%</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        </ListGroupItem>
                      ) : null}
                    </ListGroup> : null}
                </Card.Body>
                <Card.Footer className="border-0 py-2">
                  <ButtonToolbar
                    className="mb-3 justify-content-between"
                    aria-label="Bottoni"
                  >
                    <div className="my-auto">
                      {parseInt(element.division) === 1 ? (
                        <Chip
                          className={classes.roypet}
                          //color="#313d85"
                          variant="outlined"
                          avatar={
                            <Avatar
                              alt="RP"
                              src={toAbsoluteUrl("/media/products/roypet.png")}
                            />
                          }
                          label="Roypet"
                        />
                      ) : null}
                      {parseInt(element.division) === 2 ? (
                        <Chip
                          className={classes.roydermal}
                          //color="danger"
                          variant="outlined"
                          avatar={
                            <Avatar
                              alt="RD"
                              src={toAbsoluteUrl(
                                "/media/products/roydermal.png"
                              )}
                            />
                          }
                          label="Roydermal"
                        />
                      ) : null}
                    </div>
                    {element.available ? (
                      <InputGroup>
                        <Button
                          //variant="outline-success"
                          variant="outline"
                          className={elClass}
                          onClick={() =>
                            setCartValue(
                              element.id,
                              getCartValue(element.id) - 1
                            )
                          }
                        >
                          <i
                            className={"ki ki-minus icon-xs p-0 " + elClass}
                          ></i>
                        </Button>
                        <Form.Control
                          style={{
                            width: "60px",
                            height: "auto",
                            //borderColor: "var(--success)",
                          }}
                          className={elClass}
                          type="number"
                          value={getCartValue(element.id)}
                          aria-label="Quantità"
                          onChange={(event) =>
                            setCartValue(
                              element.id,
                              parseInt(event.target.value)
                            )
                          }
                        />
                        <Button
                          //variant="outline-success"
                          variant="outline"
                          className={elClass}
                          onClick={() =>
                            setCartValue(
                              element.id,
                              getCartValue(element.id) + 1
                            )
                          }
                        >
                          <i
                            className={"ki ki-plus icon-xs p-0 " + elClass}
                          ></i>
                        </Button>
                      </InputGroup>
                    ) : (
                      <p>Non disponibile</p>
                    )}
                    {/* <Button variant="outline-secondary">
                                    <span className="svg-icon svg-icon-md svg-icon-black m-0">
                                        <SVG src={toAbsoluteUrl(icons.addToCart)} />
                                    </span>
                                </Button> */}
                  </ButtonToolbar>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
      <div className="cartArea">
        <Dropdown drop="up" as={ButtonGroup}>
          <Button
            style={{ backgroundColor: color, borderColor: color }}
            className="font-weight-bold btn-inline"
            onClick={
              richiestaLoading || dialogOpen ? null : () => {
                if(!richiediDatiVendita && !richiediExtra) setRichiestaLoading(true);
                setDialogOpen(true); 
              }
            }
            disabled={richiestaLoading || dialogOpen || Object.keys(cart).length == 0}
          >
            {richiestaLoading || dialogOpen
              ? "Caricamento…"
              : "Invia richiesta"}
            <span className="svg-icon svg-icon-md m-3">
              <SVG src={toAbsoluteUrl(icons.go)} />
            </span>
          </Button>
          {Object.keys(cart).length > 0 ? (
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll"
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  position: "relative",
                }}
              >
                {Object.keys(cart)
                  .filter((id) => elementsMap.has(id))
                  .map((id, key) => (
                    <>
                      <div className="d-flex align-items-center justify-content-between p-8">
                        <div className="d-flex flex-column mr-2">
                          {/*eslint-disable-next-line*/}
                          <span className="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
                            {elementsMap.get(id).name}
                            <Button
                              key={`btn_${key}`}
                              variant="light-success"
                              className="btn-xs btn-icon ml-2"
                              onClick={() => setCartValue(id, 0)}
                            >
                              <i className="ki ki-close icon-xs"></i>
                            </Button>
                          </span>
                          <span className="text-muted">
                            {elementsMap.get(id).description.length > 0 ? (
                              elementsMap.get(id).description.length >
                              maxDescriptionLength ? (
                                <OverlayTrigger
                                  key={`ovl${key}`}
                                  placement="auto"
                                  delay={{ show: 250, hide: 400 }}
                                  trigger={["hover", "focus", "click"]}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      {elementsMap.get(id).description}
                                    </Tooltip>
                                  )}
                                >
                                  <p>
                                    {elementsMap
                                      .get(id)
                                      .description.substring(
                                        0,
                                        maxDescriptionLength
                                      ) + "..."}
                                  </p>
                                </OverlayTrigger>
                              ) : (
                                elementsMap.get(id).description
                              )
                            ) : null}
                          </span>
                          <div className="d-flex align-items-center mt-2">
                            {elementsMap.get(id).quantity ? (
                              <>
                                <span className="mr-1 text-dark-75 font-size-3">
                                  {elementsMap.get(id).quantity} 
                                  {typeof elementsMap.get(id).cost !==
                                  "undefined"
                                    ? ` (${parseFloat(
                                        elementsMap.get(id).cost
                                      )}€)`
                                    : null}
                                </span>
                                <span className="text-muted mr-1">
                                  {" "}
                                  &#215;{" "}
                                </span>
                                <span className="font-weight-bold mr-2 text-dark-75 font-size-3">
                                  {cart[id]} colli
                                </span>
                                <span className="text-muted mr-1">=</span>
                                <span className="mr-2 text-dark-75 font-size-3">
                                  {parseInt(elementsMap.get(id).quantity) *
                                    cart[id]}{" "}
                                  {displayName}
                                  {typeof elementsMap.get(id).cost !==
                                  "undefined"
                                    ? <>
                                       ({getScontoFinalValue(id)
                                        ? (<>
                                          <span style={{textDecoration: "line-through"}}>{(cart[id] * parseFloat(elementsMap.get(id).cost)).toFixed(2)}€</span>
                                          <span className="ml-2">{((1 - getScontoFinalValue(id)/100) * cart[id] * parseFloat(elementsMap.get(id).cost)).toFixed(2)}</span>
                                        </>)
                                        : (cart[id] * parseFloat(elementsMap.get(id).cost)).toFixed(2)}€)
                                    </> : null}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="mr-1 text-dark-75 font-size-3">
                                  {typeof elementsMap.get(id).cost !==
                                  "undefined"
                                    ? `${parseFloat(
                                        elementsMap.get(id).cost
                                      )}€`
                                    : null}
                                </span>
                                <span className="text-muted mr-1">
                                  {" "}
                                  &#215;{" "}
                                </span>
                                <span className="font-weight-bold mr-2 text-dark-75 font-size-3">
                                  {cart[id]}
                                </span>
                                <span className="text-muted mr-1">=</span>
                                <span className="mr-2 text-dark-75 font-size-3">
                                  {typeof elementsMap.get(id).cost !== "undefined"
                                    ? <>
                                       {getScontoFinalValue(id)
                                        ? (<>
                                          <span style={{textDecoration: "line-through"}}>{(cart[id] * parseFloat(elementsMap.get(id).cost)).toFixed(2)}€</span>
                                          <span className="ml-2">{((1 - getScontoFinalValue(id)/100) * cart[id] * parseFloat(elementsMap.get(id).cost)).toFixed(2)}</span>
                                        </>)
                                        : (cart[id] * parseFloat(elementsMap.get(id).cost)).toFixed(2)}€
                                    </> : null}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        {elementsMap.get(id).imageUrl !== "" &&
                        elementsMap.get(id).imageUrl !== "0" ? ( // TODO: REMOVE THE 0 ?
                          <div className="symbol symbol-70 flex-shrink-0">
                            <img
                              src={
                                elementsMap.get(id).imageUrl.startsWith("/")
                                  ? process.env.REACT_APP_API_URL +
                                    elementsMap.get(id).imageUrl
                                  : elementsMap.get(id).imageUrl
                              }
                              alt=""
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="separator separator-solid"></div>
                    </>
                  ))}
                <div className="p-8">
                  {elements.some(((e) => !!e.quantity)) ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                          Numero di colli
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                          {Object.values(cart).reduce((a, b) => a + b, 0)}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-7">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                          {displayName.charAt(0).toUpperCase() +
                            displayName.slice(1)}{" "}
                          totali
                        </span>
                        <span className="font-weight-bolder text-dark-50 text-right">
                          {Object.keys(cart).reduce(
                            (sum, id) =>
                              elementsMap.has(id)
                                ? sum +
                                  parseInt(elementsMap.get(id).quantity) *
                                    cart[id]
                                : sum,
                            0
                          )}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                          Numero totale
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                          {Object.values(cart).reduce((a, b) => a + b, 0)}
                        </span>
                      </div>
                    </>
                  )}
                  <div className="d-flex align-items-center justify-content-between mb-7">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                      Valore totale
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-right">
                      {Object.keys(cart).reduce(
                        (sum, id) =>
                          elementsMap.has(id) &&
                          typeof elementsMap.get(id).cost !== "undefined"
                            ? sum +
                              parseFloat(elementsMap.get(id).cost) * cart[id]
                            : sum,
                        0
                      ).toFixed(2)}
                      €
                    </span>
                  </div>
                  {richiediDatiVendita && Object.keys(cart).some((id) => getScontoFinalValue(id) > 0) ? <>
                    <div className="d-flex align-items-center justify-content-between mb-7">
                      <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Valore totale scontato
                      </span>
                      <span className="font-weight-bolder text-dark-50 text-right">
                        {Object.keys(cart).reduce(
                          (sum, id) =>
                            elementsMap.has(id) &&
                            typeof elementsMap.get(id).cost !== "undefined"
                              ? sum +
                              (1 - getScontoFinalValue(id)/100) * parseFloat(elementsMap.get(id).cost) * cart[id]
                              : sum,
                          0
                        ).toFixed(2)}
                        €
                      </span>
                    </div>
                    </> : null}
                </div>
              </PerfectScrollbar>
            </Dropdown.Menu>
          ) : null}
          <Dropdown.Toggle
            as={DropdownItemToggler}
            color={color}
            id="dropdown-toggle-my-cart-toggle"
          >
            <span className="label label-white label-pill label-inline label-sm mr-1">
              {Object.keys(cart).length}
            </span>{" "}
            {displayName}
            <span className="svg-icon svg-icon-md m-3">
              <SVG src={toAbsoluteUrl(icons.addToCart)} />
            </span>
            {Object.keys(cart).length > 0 ? (
              <i className="ki ki-arrow-up icon-xs"></i>
            ) : (
              <i className="ki ki-arrow-up icon-xs invisible"></i>
            )}
          </Dropdown.Toggle>
        </Dropdown>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="richiesta-dialog-title"
        aria-describedby="richiesta-dialog-description"
      >
        <DialogTitle id="richiesta-dialog-title">
          {result === "" 
            ? (richiestaLoading ? "Richiesta in corso" : "Finalizza richiesta") 
            : (parseInt(result) === 203 ? "Richiesta inviata" : "Errore")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="richiesta-dialog-description">
            {result === ""
              ? (richiestaLoading ? null : <>
                <Form className="row">
                  {richiediDatiVendita ? 
                    <>
                      <Form.Group className="col-12">
                        <Form.Label className="col-12">
                          {formFields["idCliente"].label}
                          <Link to={formFields["idCliente"].newLink} style={{'float': 'right'}}>{formFields["idCliente"].newLabel}</Link>
                        </Form.Label>
                        <select
                          type="text"
                          name="idCliente"
                          // value={richiestaIdCliente}
                          // onChange={(e) => {
                          //   if (e !== null) setRichiestaIdCliente(e.target.value); 
                          //   console.log(e);
                          // }}
                          className={`form-control ${getInputClasses("idCliente")}`}
                          {...formik.getFieldProps("idCliente")}
                        >
                          <option value={0}>{formFields["idCliente"].placeholder}</option>
                          {clienti.map((e, key) => (
                            <option key={key} value={e.value}>
                              {e.label}
                            </option>
                          ))}
                        </select>
                        {formik.touched["idCliente"] && formik.errors["idCliente"] ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors["idCliente"]}
                            </div>
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label className="col-12">
                          {formFields["metodoPagamento"].label}
                        </Form.Label>
                        <select
                          type="text"
                          name="metodoPagamento"
                          className={`form-control ${getInputClasses("metodoPagamento")}`}
                          {...formik.getFieldProps("metodoPagamento")}
                        >
                          <option value={0}>{formFields["metodoPagamento"].placeholder}</option>
                          {Object.entries(metodiPagamento).map((entry) => (
                            <option key={entry[0]} value={entry[0]}>
                              {entry[1]}
                            </option>
                          ))}
                        </select>
                        
                        {formik.touched["metodoPagamento"] && formik.errors["metodoPagamento"] ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors["metodoPagamento"]}
                            </div>
                          </div>
                        ) : null}
                        {scontiMetodiPagamento[formik.values.metodoPagamento] > 0 
                          ? <>
                            <br />Valore finale con ulteriore sconto: {((1 - scontiMetodiPagamento[formik.values.metodoPagamento]) * Object.keys(cart).reduce(
                              (sum, id) =>
                                elementsMap.has(id) &&
                                typeof elementsMap.get(id).cost !== "undefined"
                                  ? sum +
                                  (1 - getScontoFinalValue(id)/100) * parseFloat(elementsMap.get(id).cost) * cart[id]
                                  : sum,
                              0
                            )).toFixed(2)}€
                          </>
                          : null}
                      </Form.Group>
                      {/* aggiunto campo note mt */}
                      <Form.Group className="col-12">
                        <Form.Label className="col-12">
                            {formFields["note"].label}
                        </Form.Label>
                          <Form.Control
                              as="textarea"
                              name="note"
                              placeholder={formFields["note"].placeholder}
                              //value={note}
                              //onChange={(e) => setNote(e.target.value)}
                              rows={formFields.note.rows}
                              style={{ resize: "none" }}
                              className={getInputClasses("note")}
                              {...formik.getFieldProps("note")}
                          />
                          {formik.touched["note"] && formik.errors["note"] ? (
                              <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                      {formik.errors["note"]}
                                  </div>
                              </div>
                          ) : null}
                        </Form.Group>
                      
                    </> : null}
                  {richiediExtra ? <Form.Group className="col-12">
                      <Form.Label className="col-11">
                        {formFields["extra"].label}
                      </Form.Label>
                      <Checkbox 
                        name="extra"
                        className="form-control col-1"
                        checked={richiestaExtra}
                        onChange={(_, checked) => setRichiestaExtra(checked)}
                      />
                      <div className="col-12 text-muted">{formFields["extra"].description}</div>
                    </Form.Group> : null}
                </Form>
              </>)
              : (parseInt(result) === 203 
                ? (<>
                    La tua richiesta è stata effettuata correttamente, puoi
                    consultare le tue richieste nella tua sezione di{" "}
                    <Link to={redirectTo}>Riepilogo</Link>
                    </>
                ) : "C'è stato un errore nel processare la richiesta")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {result === "" ? (!richiestaLoading ? (
            <>
              <Button
                onClick={formik.handleSubmit} // () => setRichiestaLoading(true)
                color="primary"
                autoFocus
              >
                Conferma
              </Button>
            </>
          ): null) : (
            <Button onClick={() => {setDialogOpen(false); setResult("");}} color="primary">
              Chiudi
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ShopGridSkeleton = () => {
  return (
    <Row xs={1} md={2} xl={3} xxl={4}>
      <Col className="p-4">
        <Card>
          <Skeleton variant="rect" height={350} />
        </Card>
      </Col>
      <Col className="p-4">
        <Card>
          <Skeleton variant="rect" height={350} />
        </Card>
      </Col>
      <Col className="p-4">
        <Card>
          <Skeleton variant="rect" height={350} />
        </Card>
      </Col>
      <Col className="p-4">
        <Card>
          <Skeleton variant="rect" height={350} />
        </Card>
      </Col>
      <Col className="p-4">
        <Card>
          <Skeleton variant="rect" height={350} />
        </Card>
      </Col>
    </Row>
  );
};
